@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/elevation";
@use '$styles/mediaqueries';
@use "$styles/tools/extends/typography";

.component {
	height: 56px;
	
	@include mediaqueries.smallUp {
		// space for .sticky
		height: 80px;
	}

	:global {
		.VrmLookupModal-toggle {
			display: none;

			@include mediaqueries.smallUp {
				display: inline-flex;
			}
		}
	}
}

.MembershipBar {
	background-color: white;
}

.delimiter-right {
	margin-left: auto;
}

.call-us-cta {
	@include mediaqueries.smallUp {
		display: none !important;
	}
}

.toggleButton {
	display: none !important;
	@include mediaqueries.smallUp {
		display: inline-flex !important;
	}
}

.MechanicGarageVrmLookupModal {
	@include mediaqueries.upToMedium{
		display: none;
	}
}
