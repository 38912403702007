@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/utils';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';

.component {
  height: 50px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(195, 182, 251, 0.3);
  border-radius: 0px 0px 8px 8px;
  background: colors.$white;

  @include mediaqueries.smallUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    gap: 30px;
    padding: 0;
    border-radius: 0;
    background: colors.$purple500;
  }

  :global {
    .NavigationMenu {
      white-space: nowrap;
      .login {
        display: none;
      }
    }
    @include mediaqueries.upToLarge {
      .NavigationMenu {
        order: 1; // place hamburger icon last
        .login {
          display: block;
          margin: auto 0 125px 0;
          @include typography.labelLTypographyStyles;
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }
  }

  @include mediaqueries.smallUp {
    &:global(.transparent) {
      background-color: transparent;
    }
    &:global(.transition) {
      transition: background-color 100ms ease-out;
    }
  }
}

.Row {
  width: 100%;
}

.Col {
  display: flex;
  gap: 5px;
  align-items: center;

  @include mediaqueries.smallUp {
    gap: 30px;
  }

  .login {
    line-height: 28px;
    @include utils.anchorColor(colors.$white);
    text-decoration: underline;
    font-weight: 600;
  }

  > .login {
    display: none;

    @include mediaqueries.largeUp {
      display: inline;
    }
  }
}

.GarageMemberPageNav {
  display: flex;
  justify-content: flex-end;

  @include mediaqueries.smallUp {
    align-items: center;
    gap: 30px;
  }
  > :first-child {
    margin-right: auto;
  }
}